.cs-page {
  .content-container {
    .cs-page__navigation {
      .content {
        .collapsible-menu {
          .cs-menu__static {
            &.cs-menu__contact-us {
              margin: 1em 2em;
              @include breakpoint($medium-up) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
