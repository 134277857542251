$lookup_background_color: #d8d8d8;

.checkout {
  @include opc_pickpoint;
  &.viewcart {
    .checkout-panel {
      &--favorites,
      &--past-purchases {
        display: none;
      }
      &--order-summary {
        .value {
          width: 28%;
        }
      }
    }
    .checkout-buttons-content {
      &__container {
        width: 100%;
      }
    }
  }
  &.checkout-single-page {
    .cart-items {
      &__item--qty {
        text-align: center;
        margin-top: 0;
      }
    }
  }
  &__content,
  &__sidebar {
    .offer-code-content {
      &__sign-in-link {
        display: none;
        margin-bottom: 10px;
      }
      &__form {
        padding-top: 10px;
      }
    }
  }
  .recommended-item {
    width: 48%;
    margin-right: 2%;
    &__cta {
      .button {
        white-space: pre-wrap;
      }
    }
  }
  .cart-items {
    &__item {
      &:last-child {
        border-bottom: 0;
      }
      &--qty {
        #cart {
          .selectbox {
            min-width: 60px;
          }
        }
      }
    }
  }
  .checkout-panel {
    padding-left: 11px;
    padding-right: 11px;
    @include breakpoint($medium-up) {
      padding-left: 1px;
      margin-left: 6px;
    }
    &--sign-in-main {
      border-bottom: none;
      &.active,
      &.finished {
        border-bottom: none;
      }
    }
    &--shipping {
      #shipping-panel-edit {
        display: none;
      }
      .new-address-container {
        .form-item {
          &.country-id {
            padding: 0 0 20px 0;
            select.country_id {
              font-size: 12px;
              height: calc(3.4em - -2px);
            }
          }
          &.address3 {
            padding: 0 0 20px 0;
          }
          &.default-shipping {
            padding: 0;
          }
        }
      }
      .ship-method-group-label {
        .radio-wrapper {
          display: block;
          padding: 1em 0;
        }
        .click-and-collect {
          margin-left: 10px;
        }
      }
      .ship-method-group {
        margin: 10px 0;
      }
    }
    &--email-and-sms-promotions {
      border-bottom: none;
      .pc_email_promo_container {
        padding-top: 5px;
      }
    }
    .payment-edit-content {
      h3.checkout__subtitle {
        font-weight: bold;
      }
      .payment-type {
        span.form-item {
          display: block;
        }
      }
    }
    .loading {
      .text {
        padding-top: 100px;
      }
    }
  }
  .ac_list {
    border: 1px solid $color-dark-gray;
    overflow: hidden;
    padding: 0;
    width: 97%;
    z-index: 20;
    margin-top: 1em;
    ul {
      height: auto;
      list-style: outside none none;
      margin: 0;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      width: 100%;
      li {
        border-top: 1px solid $color-dark-gray;
        cursor: pointer;
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 2px 5px;
        position: relative;
        &:first-child {
          border-top: none;
        }
      }
      .ac_hover {
        background-color: $lookup_background_color;
      }
    }
  }
  &--confirmation-page {
    .nolink_order_id {
      a {
        border: none;
        cursor: default;
        font-weight: bold;
        pointer-events: none;
      }
    }
  }
  &.checkout_index,
  &.shipping {
    padding-top: 0px;
  }
  .checkout__content {
    .checkout-panel--sub-section {
      padding-top: 45px;
    }
  }
  &__sidebar {
    .checkout-panel {
      &--viewcart {
        .link {
          &.edit {
            text-transform: uppercase;
          }
        }
      }
      @include breakpoint($medium-up) {
        .order-summary-content {
          .order-summary-content__label {
            width: 64%;
          }
          .order-summary-content__value {
            width: 35%;
          }
        }
      }
    }
    @include breakpoint($medium-up) {
      padding: 45px 2px;
      margin-left: 1.7%;
      width: 35%;
      .offer-code-content--input {
        &.form-item--full {
          width: 100%;
          padding-right: 0;
        }
      }
      .offer-code-content--submit {
        width: 100%;
      }
    }
  }
  &.samples-page {
    .product-list__item {
      .product-subhead {
        margin-left: 37px;
      }
      li.sku {
        .sample-label-sku {
          &:before {
            margin-top: -6px;
          }
        }
      }
    }
  }
}

#shipmethod-panel {
  overflow: unset;
}
