.sign-in {
  .sign-in-page {
    .new-account,
    .return-user {
      .form-item {
        input[type='checkbox'] {
          width: auto;
        }
      }
    }
  }
}
