.column-main {
  .search-wrapper {
    .product-rating {
      @include swap_direction(margin, 10px auto);
      position: static;
      .pr-snippet {
        .pr-star-v4 {
          height: 25px;
          background-repeat: no-repeat;
        }
      }
    }
    .results {
      &__products {
        margin-bottom: 50px;
      }
    }
  }
}

.notify-me-overlay-cbox {
  .waitlist-form {
    padding-#{$rdirection}: 25px;
  }
}
