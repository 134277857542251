//Including pickpoint
@import 'pickpoint';

.site-footer {
  .site-email-signup__wrapper--email {
    width: calc(88% - 6em);
  }
}

.section-gifting {
  .mpp__show-column-header {
    .product-grid {
      .carousel-dots {
        top: 370px;
      }
      .product-brief {
        &__quickshop-launch-wrapper {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #ship-calc-overlay {
    .close-popup {
      margin-left: 0 !important;
    }
  }
}

.shipping-calc-results {
  display: block;
  margin: 10px 30px 10px 0px;
  font-size: 16px;
  .shipping-cost {
    .country-container {
      float: left;
      margin-top: -10px;
    }
  }
}

@media screen and (min-width: 767px) {
  .shipping-calc-results {
    .shipping-cost {
      .country-container {
        float: none !important;
        display: block;
      }
    }
  }
}

.device-mobile {
  .shipping-cost-widget {
    margin-bottom: -18px;
    p.delivery_region {
      padding: 5px 0px 13px 0px;
    }
    .shipping-cost {
      margin: 1.5em 0;
    }
    .shipping-price {
      margin-right: 5px;
    }
    .shipping-title {
      font-weight: bold;
      margin-bottom: 2px;
    }
    .city_region {
      line-height: 20px;
      font-size: 16px;
    }
  }
  a.shipping_calc {
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
  }
  #ship-calc-overlay {
    .country-container {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        float: left;
        text-transform: uppercase;
      }
      span.label-content {
        text-transform: uppercase;
      }
      select {
        height: 42px;
        padding-left: 7px !important;
      }
    }
    .calculation-result {
      .shipping-type {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .close-popup {
      padding: 6px 10px;
      background: #000;
      margin-left: 15px;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      float: right;
      height: 31px;
    }
    .input-btn {
      border-radius: 0px !important;
      padding: 6px 10px;
      background: #000;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      float: left;
      height: 31px;
    }
    .submit_popup {
      margin-top: 15px;
    }
    .missing_region,
    .missing_city {
      color: red !important;
    }
  }
  .shipping-calc-link {
    display: block;
    font-size: 12px;
  }
}
