// Abstract specific font names away
$font--heading: 'EseninScriptOneFont' !default;

$kilian-esenin-script-one_font: 'EseninScriptOneFont';

$font-kilian-folder: 'esenin_script_one';

$font-filename-esenin-script-one: 'EseninScriptOne';

@font-face {
  font-family: '#{$kilian-esenin-script-one_font}';
  font-style: normal;
  src: url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.eot);
  src: url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.eot?#iefix)
      format('embedded-opentype'),
    url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.woff2) format('woff2'),
    url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.woff) format('woff'),
    url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.ttf) format('truetype'),
    url(/media/fonts/#{$font-kilian-folder}/#{$font-filename-esenin-script-one}.svg#Simplified-Chinese-font)
      format('svg');
}

h1,
.h1 {
  font-family: $kilian-esenin-script-one_font;
}

.cs-page header .cs-page__title h1 {
  font-family: $kilian-esenin-script-one_font;
}

.store-locator__title,
.content-block1__heading,
.content-block2__heading,
.product-brief__container .product-brief__product-header-blurb,
.search-wrapper .search-wrapper__header,
.search-wrapper__header h1 {
  font-family: $kilian-esenin-script-one_font;
}

.product-full__name,
.product-quickshop__name {
  font-family: $kilian-esenin-script-one_font;
}

.samples__kit__hero .hero-large__text.font--medium {
  font-family: $kilian-esenin-script-one_font;
}
