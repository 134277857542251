.show-password {
  input[type='checkbox'] {
    & ~ .label {
      .label-content {
        padding-top: 6px;
      }
    }
  }
}

.account-section {
  .profile-page {
    &__container--form {
      .optional-info {
        select {
          min-width: auto;
        }
      }
      h3 {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .profile-info {
        &__item {
          &_full {
            select {
              margin-bottom: 10px;
              margin-top: 10px;
            }
          }
        }
        &__title {
          select {
            width: 100%;
          }
        }
      }
      .newsletter-info {
        &__text--new {
          margin-bottom: 1em;
        }
        &__item {
          margin-bottom: 1em;
        }
      }
    }
  }
  .account-utilities {
    &__section {
      .sidebar-menu {
        padding-bottom: 25px;
        &__item {
          a {
            border-top: none;
          }
        }
      }
      h3 {
        padding: 0 25px;
      }
    }
  }
  .address-item {
    &__controls {
      a {
        border-bottom: none;
      }
    }
  }
  &__content {
    .account-page__section {
      padding-bottom: 1em;
    }
    .section-content {
      clear: both;
    }
    .section-head {
      &__header {
        float: #{$ldirection};
      }
      &__link {
        float: #{$rdirection};
      }
    }
  }
  .order-products {
    .product {
      .thumb {
        width: 20%;
        float: #{$ldirection};
        margin-#{$rdirection}: 2%;
      }
      .cart-item {
        &__desc {
          width: 50%;
          float: #{$ldirection};
        }
        &__price {
          display: none;
        }
        &__qty,
        &__total {
          width: 15%;
          float: #{$ldirection};
        }
      }
    }
  }
  &.orders-page {
    .orders-list {
      &__container {
        margin-top: 10px;
      }
    }
  }
  a.back-link {
    &.pc-hidden {
      top: 0;
      border-bottom: 0;
    }
  }
}

#address_form_container {
  padding: 10px 20px;
  .address-form {
    .form_element {
      padding-bottom: 1em;
      &.title_container {
        display: inline-block;
        width: 100%;
      }
      &.default_ship_container {
        width: 100%;
        padding-top: 10px;
      }
    }
    &__submit {
      margin-top: 10px;
    }
  }
}

.address-delete {
  padding: 20px;
  h5 {
    line-height: 1em;
    font-size: 1.5em;
    margin: 10px 0;
    padding-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid $color-black;
  }
  a {
    @include swap_direction(margin, 10px 10px 0 0);
    color: $color-pink;
    border-bottom: 1px solid $color-pink;
  }
}

.address-book-page {
  .address-book-page__content {
    padding-top: 0;
  }
}
