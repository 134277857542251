///
/// @file layout/_footer.scss
///
/// \brief Styles for site-wide page footers
///
///

.site-footer {
  .site-email-signup__wrapper--email {
    width: calc(100% - 10em);
    @include breakpoint($medium-up) {
      width: calc(100% - 9em);
    }
  }
  .basic-noderef--email-signup {
    width: 100%;
    max-width: 30em;
    @include breakpoint($medium-up) {
      width: 30em;
    }
  }
  .site-email-signup__submit {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .email-millennial-wrapper {
    .subscribe-condtions {
      width: 100%;
    }
  }
}
