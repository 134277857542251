.site-header {
  .menu-ref {
    &--the-brand {
      .menu__item {
        &--category {
          font-weight: bold;
          @include breakpoint($landscape-up) {
            font-weight: 700;
          }
        }
      }
    }
  }
  .menu__item--lvl-3 {
    height: auto;
    &.menu__item--back {
      overflow: hidden;
    }
  }
}
