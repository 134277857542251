.returns,
.customer-service {
  padding: 20px;
}

.adpl {
  input[type='tel'],
  input[type='text'] {
    &.error {
      border-color: $color-red;
    }
  }
}

.colorbox--video {
  #cboxClose {
    display: block;
    color: $color-white;
  }
}
