.section-home {
  .homepage__split {
    .basic-formatter__items {
      padding: 60px 0 60px 0;
      @include breakpoint($medium-up) {
        padding: 110px 20px 130px 20px;
      }
    }
  }
  .hero-carousel-formatter {
    .hero-carousel__slide {
      .content-block2__inner {
        @include breakpoint($landscape-up) {
          .content-block2__heading {
            margin-bottom: 0;
          }
          .content-block2__subheading {
            margin-bottom: 15px;
            margin-top: 10px;
          }
        }
      }
    }
    .slick-slider {
      .slick-next,
      .slick-arrow {
        top: 30%;
        color: $color-white;
        @include breakpoint($landscape-up) {
          top: 50%;
          color: $color-black;
        }
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

.hero-carousel-formatter {
  .content-block2 {
    .content-block2__image {
      max-height: 700px;
    }
  }
}

.error {
  color: $color-red;
}

.account-section {
  .account-payment {
    display: none;
  }
}

.profile-page {
  &__content {
    .newsletter-info {
      &__item {
        .label-content {
          display: table;
        }
      }
    }
  }
}

.past-purchases {
  &__item {
    .product-content {
      padding: 10px 0;
    }
  }
}

.password-reset-page {
  .password-reset {
    &__item {
      margin-top: 5px;
    }
  }
}
// Power reviews
#pr-reviewdisplay {
  .p-w-r {
    .pr-rd-footer {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Да -';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'нет -';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.p-w-r {
  .pr-checkbox {
    input[type='checkbox'] {
      left: 0;
      top: 2px;
    }
  }
}

.notify-me-overlay-cbox {
  #cboxLoadingOverlay,
  #cboxLoadingGraphic {
    visibility: hidden;
  }
  .waitlist-form {
    &__email-promotions-section {
      position: relative;
      margin-top: 10px;
    }
    &__email-promotions {
      left: 0;
      width: 20px;
      height: 20px;
    }
  }
}
