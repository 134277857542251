.sign-in-page {
  .section-head {
    &__header {
      margin-bottom: 20px;
    }
  }
  .new-account,
  .return-user {
    .form-item {
      &.email-address {
        margin-top: 20px;
      }
    }
  }
}

.btn {
  &.form-submit {
    margin-bottom: 20px;
  }
}

.password-sent-page {
  .sent-info {
    &__text {
      .password-phone {
        margin: 0;
        text-decoration: none;
      }
    }
  }
}
