.social_login_v1 {
  &__delimeter {
    padding: 0 18px 25px;
    text-align: center;
    height: 10px;
    clear: both;
    &-horizontal {
      background-color: $color-gray;
      height: 2px;
      position: relative;
    }
    &-label {
      padding: 0 10px;
      bottom: 100%;
      #{$ldirection}: 50%;
      transform: translate(-19px, 50%);
      position: absolute;
      background: $color-white;
    }
  }
}

.social-login-container {
  text-align: center;
  &__social-network {
    height: 50px;
    width: 50px;
    display: inline-block;
    margin: 0 25px;
    position: relative;
    @include breakpoint($medium-up) {
      width: 230px;
      height: 40px;
    }
    &-button {
      border: none;
      border-radius: 5px;
      letter-spacing: 0.25px;
      height: 100%;
      width: 100%;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 40px;
      }
      &-label {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      &_vkontakte,
      &_vkontakte:hover {
        background: $color-vkontakte url(/media/images/social_login/vk_icon.svg) 8px center no-repeat;
      }
      &_facebook,
      &_facebook:hover {
        background: $color-facebook url(/media/images/social_login/fb_icon.svg) 0 center no-repeat;
        background-size: 40px 40px;
      }
    }
    &-blocker {
      position: absolute;
      width: 100%;
      height: 100%;
      &_hidden {
        display: none;
      }
    }
  }
}

.sign-in-component_ru__legal-container {
  width: 81%;
  margin: 0 auto;
  font-size: 12px;
  text-align: justify;
  padding-bottom: 50px;
  .legal-container__acceptance-block {
    padding-top: 15px;
    &_error {
      .text--checkbox-label {
        color: $color-red;
      }
    }
  }
}
/* Order Confirmation Page */
.confirmation-content {
  .social_login_v1 {
    &__delimeter {
      padding-top: 30px;
    }
  }
  .sign-in-component_ru {
    &__legal-container {
      width: 80%;
      @include breakpoint($medium-up) {
        width: 61%;
      }
    }
  }
}
/* Account profile Page */
.social-info {
  &__vk {
    margin-bottom: 20px;
    #vkontakte_disconnect {
      margin-#{$ldirection}: 10px;
      display: inline-block;
    }
    .vkontakte-login {
      @include swap_direction(padding, 0 9px 0 30px);
      background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 5px no-repeat;
      background-size: 18px;
      margin-#{$ldirection}: 5px;
      height: 21px;
      font-size: 9px;
      letter-spacing: 0.5px;
      border-radius: 4px;
      border: none;
    }
  }
}

.social-login-combined {
  .checkout-panel--new-account,
  .checkout-panel--return-user {
    @include breakpoint($medium-up) {
      position: relative;
      .form-item.submit {
        bottom: 0 !important;
        width: 50%;
      }
    }
  }
  form#checkout_signin_guest_user {
    .form-item.submit {
      @include breakpoint($medium-up) {
        top: 232px;
      }
    }
  }
  .social_login_v1__delimeter {
    padding: 25px 0;
  }
  .social-login-container {
    &__social-network {
      margin-top: 10px;
    }
  }
}
