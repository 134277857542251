/* Checkout Signin Page */
.checkout-single-page {
  .checkout__content {
    .new-account-content,
    .return-user-content {
      .form-item.submit {
        bottom: 36%;
        @include breakpoint($tablet-only) {
          bottom: 41%;
        }
      }
    }
    .social-login {
      margin: 30px 0;
      @include breakpoint($tablet-only) {
        margin: 0;
      }
      @include breakpoint($medium-up) {
        margin: 10px 0;
      }
      &__divider {
        width: 100%;
        margin-left: 0;
        &:before {
          top: 10px;
          @include breakpoint($medium-up) {
            top: 12px;
          }
        }
      }
      .fb-login-button {
        text-align: center;
        display: block;
      }
      &__title,
      &__info {
        display: none;
      }
      &__email-opt-in,
      &__terms {
        padding-top: 15px;
      }
    }
  }
}
/* Order Confirmation Page */
.checkout--confirmation-page {
  .social-login {
    margin-top: 30px;
    border-top: 1px solid $color-black;
    padding-top: 20px;
    &__divider,
    &__terms {
      display: none;
    }
    &__info {
      p {
        margin-bottom: 15px;
      }
    }
    &__email-opt-in {
      margin-top: 20px;
    }
  }
}
/* Account Profile Page */
.profile-page__content {
  .social-info {
    margin-top: 100px;
    h3 {
      font-weight: bold;
    }
    &__connect {
      margin-bottom: 15px;
      display: flex;
      span {
        margin-top: 12px;
        @include breakpoint($medium-up) {
          margin-top: 8px;
        }
      }
      #facebook_disconnect {
        margin-#{$ldirection}: 15px;
      }
      .fb-login-button {
        margin-#{$ldirection}: 10px;
      }
    }
  }
}
/* Account Signin Page */
.sign-in-page {
  .sign-in-page__container {
    .social-login {
      &__title,
      &__info {
        display: none;
      }
      &__divider {
        width: 92%;
        margin-left: 4%;
        &:before {
          top: 10px;
          @include breakpoint($medium-up) {
            top: 12px;
          }
        }
      }
      .fb-login-button {
        text-align: center;
        display: block;
        margin-bottom: 15px;
      }
      &__email-opt-in,
      &__terms {
        padding-left: 15px;
        padding-bottom: 15px;
        @include breakpoint($medium-up) {
          margin-bottom: 15px;
          padding: 0 10px 0 38px;
        }
      }
    }
  }
}
/* Login Pages */
.social-login {
  &__divider {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 86%;
    margin-left: 7%;
    &:before {
      content: '';
      background: $color-light-stone-gray;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 12px;
      left: 0;
    }
    .inner {
      background: $color-white;
      display: inline-block;
      position: relative;
      padding: 0 10px;
    }
  }
}
/* Write a Review Pages */
#power_review_container {
  .social-login {
    &__divider {
      margin-left: 0;
      width: 100%;
      &:before {
        top: 13px;
        @include breakpoint($medium-up) {
          top: 19px;
        }
      }
      .inner {
        font-size: 16px;
        color: $color-black;
      }
    }
    .fb-login-button {
      text-align: center;
      display: block;
    }
    &__email-opt-in,
    &__terms {
      padding-top: 15px;
      color: $color-black;
    }
  }
}
