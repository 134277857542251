.checkout {
  .cart-items {
    &__item {
      &--desc {
      }
      &--qty {
        width: 16%;
        #cart {
          .selectbox {
            min-width: auto;
            width: 100%;
            .selectBox-label {
              @include swap_direction(padding, 0 30px 0 15px);
            }
          }
        }
      }
      &--total {
        width: auto;
      }
    }
  }
  .recommended-item {
    float: #{$ldirection};
    width: 50%;
    margin-bottom: 20px;
  }
  .offer-code-content {
    &__sign-in-link {
      margin-bottom: 10px;
      display: inline-block;
    }
    &__form {
      input {
        min-width: auto;
      }
      .button:hover {
        background: $color-pink;
      }
      .invalid_marker {
        bottom: 15px;
        background-color: transparent;
      }
    }
  }
  .checkout-buttons-content {
    &__container {
      a {
      }
    }
  }
  .checkout-panel {
    &__content {
      .valid_marker {
        background-color: transparent;
        #{$rdirection}: 23px;
        bottom: 13px;
      }
      .invalid_marker {
        bottom: 15px;
        background-color: transparent;
      }
    }
    &__header {
      a.edit.button {
        color: $color-white;
        padding-bottom: 10px;
      }
    }
    &--shipping,
    &--review {
      .new-address-container,
      .new-address {
        margin-top: 10px;
        .form-item {
          width: 100%;
          padding-#{$rdirection}: 0;
          &.address3 {
            padding-bottom: 0;
          }
          &.state {
            padding-bottom: 0em;
          }
          &.country-id {
            padding-bottom: 0;
          }
        }
      }
    }
    &--shipping {
      .address-to-use {
        margin-bottom: 1em;
        .ship_group_1 {
          label {
            display: block;
          }
        }
      }
    }
    &--review {
      .payment-type {
        label:first-child {
          display: none;
        }
        margin-bottom: 1em;
      }
      .adpl {
        .title {
          label {
            display: none;
          }
        }
      }
    }
    &.active,
    &.finished {
      border-bottom: 1px solid $color-light-gray;
    }
  }
  &__content {
    .checkout-panel {
      &.paypal,
      &--loyalty-join {
        padding: 0;
        border-bottom: 0;
      }
    }
  }
  &--confirmation-page {
    .accepted-privacy-policy {
      .label::before,
      label::before {
        content: '\E009';
      }
      input {
        &.error ~ label,
        &.checkedEmpty ~ label {
          color: $color-red;
        }
      }
    }
    .error_messages {
      .fe_validation_error {
        color: $color-red;
      }
    }
    .nolink_order_id {
      font-weight: 500;
      color: $color-black;
      letter-spacing: 0.1em;
      font-size: 14px;
    }
  }
  &.samples-page {
    .samples-content {
      &__button-group--top {
        display: none;
      }
    }
    .product-list {
      li.product {
        list-style: none;
        padding: 0 10px 0;
        margin: 0 0 20px;
        display: inline-block;
        text-align: center;
        vertical-align: bottom;
        a.sample-select-button {
          width: auto;
          margin: 15px 0 0;
        }
        input[type='checkbox'] ~ label,
        input[type='checkbox'] ~ .label {
          text-align: #{$ldirection};
          .product-name,
          .product-subhead {
            font-size: 11px;
          }
        }
        input.sample-select {
          float: #{$ldirection};
          margin-#{$rdirection}: 0.3em;
        }
      }
    }
  }
}

.order_table_title {
  text-align: #{$ldirection};
  color: #000000;
}

.order_table_value {
  text-align: #{$rdirection};
  color: #000000;
  &.price {
    font-weight: bold;
  }
}

.full_width {
  width: 100%;
}

#sample {
  &-controls {
    #sample {
      &-disclaimer {
        @include swap_direction(margin, 20px 10px 0 10px);
        padding: 0;
      }
    }
  }
}
